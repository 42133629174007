var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content
    ? _c(
        "div",
        { class: _vm.classes },
        [
          _c(
            "router-link",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple",
                  value: _vm.isPublishable
                    ? _vm.$utilities.getRippleOptions("light")
                    : false,
                  expression:
                    "isPublishable ? $utilities.getRippleOptions('light') : false",
                },
              ],
              attrs: { to: _vm.getContentDetailPath(_vm.content.recipe_id) },
              on: { click: _vm.onCardClick },
            },
            [
              _c("div", { staticClass: "dp-brew-history-card__head" }, [
                _c(
                  "div",
                  { staticClass: "dp-brew-history-card__head-datetime" },
                  [
                    _vm.content.created_at
                      ? [
                          _c("Icon", {
                            attrs: {
                              name: "dpHistoryB",
                              color: "dpBlack01",
                              width: 16,
                              height: 16,
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDpDateTime(_vm.content.created_at)
                              ) +
                              " "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "dp-brew-history-card__head-button" },
                  [
                    _c("DpMyrecipeButton", {
                      attrs: {
                        id: _vm.content.recipe_id,
                        state: _vm.content.is_bookmark,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.content.image_url
                ? _c(
                    "div",
                    { staticClass: "dp-brew-history-card__thumbnail" },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.content.image_url,
                          alt: _vm.content.name,
                        },
                        on: { load: _vm.onImgLoaded },
                      }),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "dp-brew-history-card__content" }, [
                _vm.content.name
                  ? _c("h2", { staticClass: "dp-brew-history-card__title" }, [
                      _vm._v(" " + _vm._s(_vm.content.name) + " "),
                    ])
                  : _vm._e(),
                _vm.content.capsule && _vm.isPublishPeriod(_vm.content.capsule)
                  ? _c(
                      "div",
                      { staticClass: "dp-brew-history-card__capsule" },
                      [
                        _c("Icon", {
                          attrs: {
                            name: "capsuleR",
                            color: "dpGrey66",
                            width: 15,
                            height: 15,
                          },
                        }),
                        _c(
                          "span",
                          { staticClass: "dp-brew-history-card__capsule-name" },
                          [_vm._v(_vm._s(_vm.content.capsule.name))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.content.barista &&
                _vm.isPublishPeriod(_vm.content.barista) &&
                _vm.content.barista.name_alphabetical
                  ? _c("p", { staticClass: "dp-brew-history-card__pro" }, [
                      _c(
                        "span",
                        { staticClass: "dp-brew-history-card__pro-by" },
                        [_vm._v("by")]
                      ),
                      _c(
                        "span",
                        { staticClass: "dp-brew-history-card__pro-name" },
                        [_vm._v(_vm._s(_vm.content.barista.name_alphabetical))]
                      ),
                    ])
                  : _vm._e(),
              ]),
              !_vm.isPublishable
                ? _c("p", { staticClass: "dp-brew-history-card__closed" }, [
                    _vm._v(
                      " " + _vm._s(_vm.WORDS.DRIP_POD_HISTORY_LIST.CLOSED) + " "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }